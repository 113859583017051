import React from 'react';
import {
  RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY,
  RCE_FILE_TOO_BIG,
  RCE_FILE_UPLOAD_LIMITED,
  RCE_FILE_UPLOAD_FAILURE,
} from '@wix/communities-blog-client-common';
import {
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_SUBSCRIBED,
  CATEGORY_UNSUBSCRIBED,
  CATEGORY_UPDATE,
  COMMENT_DELETE,
  COMMENT_UPDATE,
  DRAFT_DELETE,
  DRAFT_DUPLICATE,
  DRAFT_MOVE_TO_TRASH,
  LOGO_SAVED,
  MEMBER_BLOCK,
  MEMBER_DEMOTE_WRITER,
  MEMBER_PROMOTE_WRITER,
  MEMBER_UNBLOCK,
  NOT_PUBLISHED_BLOG_POST_PUBLISH,
  POST_CHANGES_DISCARDED,
  POST_CHANGES_NOT_PUBLISHED,
  POST_CHANGE_CATEGORY,
  POST_DELETE,
  POST_DELETE_ANOTHER_WRITER_EDITING_ERROR,
  POST_DISABLE_COMMENTING,
  POST_DUPLICATE,
  POST_ENABLE_COMMENTING,
  POST_MOVE,
  POST_MOVE_TO_TRASH,
  POST_PIN,
  POST_PUBLISH,
  POST_RESTORE,
  POST_REVERTED_TO_DRAFT,
  POST_SUBMITTED_FOR_MODERATION,
  POST_SUBSCRIBED,
  POST_UNPIN,
  POST_UNSUBSCRIBED,
  POST_UPDATE,
  POST_UPDATED_IN_MODERATION,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FAILED,
  REQUIRE_CATEGORY_FIELDS,
  REQUIRE_POST_FIELDS,
  REQUIRE_PROFILE_FIELDS,
  SETTINGS_UPDATED,
  USER_SUBSCRIBED,
  USER_UNSUBSCRIBED,
} from './message-types';

const Content = ({ i18nKey, t }) => (
  <span dangerouslySetInnerHTML={{ __html: t(i18nKey) }} />
);

export default {
  [POST_RESTORE]: {
    content: (t) => <Content i18nKey="messages.post-restored" t={t} />,
    type: 'success',
  },
  [POST_MOVE_TO_TRASH]: {
    content: (t) => <Content i18nKey="messages.post-moved-to-trash" t={t} />,
    type: 'success',
  },
  [POST_PIN]: {
    content: (t) => <Content i18nKey="messages.post-pinned" t={t} />,
    type: 'info',
  },
  [POST_UNPIN]: {
    content: (t) => <Content i18nKey="messages.post-unpinned" t={t} />,
    type: 'info',
  },
  [POST_DELETE]: {
    content: (t) => <Content i18nKey="messages.post-deleted" t={t} />,
    type: 'success',
  },
  [POST_DELETE_ANOTHER_WRITER_EDITING_ERROR]: {
    content: (t) => (
      <Content
        i18nKey="messages.post-delete-another-writer-editing-error"
        t={t}
      />
    ),
    type: 'error',
  },
  [POST_PUBLISH]: {
    content: (t) => <Content i18nKey="messages.post-published" t={t} />,
    type: 'success',
  },
  [POST_REVERTED_TO_DRAFT]: {
    content: (t) => <Content i18nKey="messages.post-reverted-to-draft" t={t} />,
    type: 'success',
  },
  [POST_CHANGES_DISCARDED]: {
    content: (t) => <Content i18nKey="messages.post-changes-discarded" t={t} />,
    type: 'success',
  },
  [POST_CHANGES_NOT_PUBLISHED]: {
    content: (t) => (
      <Content i18nKey="messages.post-changes-not-published" t={t} />
    ),
    type: 'info',
  },
  [POST_UPDATE]: {
    content: (t) => <Content i18nKey="messages.post-updated" t={t} />,
    type: 'success',
  },
  [NOT_PUBLISHED_BLOG_POST_PUBLISH]: {
    type: 'info',
  },
  [POST_ENABLE_COMMENTING]: {
    content: (t) => <Content i18nKey="messages.commenting-enabled" t={t} />,
    type: 'info',
  },
  [POST_DISABLE_COMMENTING]: {
    content: (t) => <Content i18nKey="messages.commenting-disabled" t={t} />,
    type: 'info',
  },
  [POST_DUPLICATE]: {
    content: (t) => <Content i18nKey="messages.post-duplicated" t={t} />,
    type: 'success',
  },
  [DRAFT_MOVE_TO_TRASH]: {
    content: (t) => <Content i18nKey="messages.draft-moved-to-trash" t={t} />,
    type: 'success',
  },
  [DRAFT_DELETE]: {
    content: (t) => <Content i18nKey="messages.draft-deleted" t={t} />,
    type: 'success',
  },
  [DRAFT_DUPLICATE]: {
    content: (t) => <Content i18nKey="messages.draft-duplicated" t={t} />,
    type: 'success',
  },
  [USER_SUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.user-subscribed" t={t} />,
    type: 'info',
  },
  [USER_UNSUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.user-unsubscribed" t={t} />,
    type: 'info',
  },
  [POST_SUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.post-subscribed" t={t} />,
    type: 'info',
  },
  [POST_UNSUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.post-unsubscribed" t={t} />,
    type: 'info',
  },
  [POST_MOVE]: {
    content: (t) => <Content i18nKey="messages.post-moved" t={t} />,
  },
  [POST_CHANGE_CATEGORY]: {
    content: (t) => <Content i18nKey="messages.post-category-changed" t={t} />,
  },

  [COMMENT_UPDATE]: {
    content: (t) => <Content i18nKey="messages.comment-updated" t={t} />,
    type: 'info',
  },
  [COMMENT_DELETE]: {
    content: (t) => <Content i18nKey="messages.comment-deleted" t={t} />,
    type: 'info',
  },

  [REQUIRE_POST_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'title':
          return <Content i18nKey="messages.post-title-required" t={t} />;
        case 'content':
          return <Content i18nKey="messages.post-content-required" t={t} />;
        default:
          return <Content i18nKey="messages.post-fields-required" t={t} />;
      }
    },
    type: 'error',
  },

  [REQUIRE_CATEGORY_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'label':
          return <Content i18nKey="messages.category-title-required" t={t} />;
        default:
          return <Content i18nKey="messages.category-fields-required" t={t} />;
      }
    },
    type: 'error',
  },

  [MEMBER_PROMOTE_WRITER]: {
    content: (t) => <Content i18nKey="messages.user-promoted-writer" t={t} />,
  },
  [MEMBER_DEMOTE_WRITER]: {
    content: (t) => <Content i18nKey="messages.user-demoted-writer" t={t} />,
  },

  [MEMBER_BLOCK]: {
    content: (t) => <Content i18nKey="messages.user-blocked" t={t} />,
  },
  [MEMBER_UNBLOCK]: {
    content: (t) => <Content i18nKey="messages.user-unblocked" t={t} />,
  },

  [CATEGORY_DELETE]: {
    content: (t) => <Content i18nKey="messages.category-deleted" t={t} />,
  },
  [CATEGORY_CREATE]: {
    content: (t) => <Content i18nKey="messages.category-created" t={t} />,
    type: 'success',
  },
  [CATEGORY_UPDATE]: {
    content: (t) => <Content i18nKey="messages.category-updated" t={t} />,
    type: 'success',
  },
  [CATEGORY_SUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.category-subscribed" t={t} />,
  },
  [CATEGORY_UNSUBSCRIBED]: {
    content: (t) => <Content i18nKey="messages.category-unsubscribed" t={t} />,
  },

  [REQUIRE_PROFILE_FIELDS]: {
    content: (t, props) => {
      const { name, image, cover } = props;
      if (name) {
        return <Content i18nKey="messages.profile-name-required" t={t} />;
      }
      if (image) {
        return <Content i18nKey="messages.profile-image-uploading" t={t} />;
      }
      if (cover) {
        return <Content i18nKey="messages.profile-cover-uploading" t={t} />;
      }
      return <Content i18nKey="messages.profile-unknown-error" t={t} />;
    },
    type: 'error',
  },
  [PROFILE_UPDATE]: {
    content: (t) => <Content i18nKey="messages.profile-updated" t={t} />,
  },
  [PROFILE_UPDATE_FAILED]: {
    content: (t) => <Content i18nKey="messages.profile-update-failed" t={t} />,
    type: 'error',
  },
  [SETTINGS_UPDATED]: {
    content: (t) => <Content i18nKey="messages.settings-saved" t={t} />,
    type: 'success',
  },
  [LOGO_SAVED]: {
    content: (t) => <Content i18nKey="messages.logo-saved" t={t} />,
    type: 'success',
  },
  [RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: (t) => (
      <Content i18nKey="messages.download-will-begin-shortly" t={t} />
    ),
    type: 'info',
  },
  [RCE_FILE_TOO_BIG]: {
    content: (t) => <Content i18nKey="messages.file-too-big" t={t} />,
    type: 'error',
  },
  [RCE_FILE_UPLOAD_LIMITED]: {
    content: (t) => <Content i18nKey="messages.file-upload-limited" t={t} />,
    type: 'error',
  },
  [RCE_FILE_UPLOAD_FAILURE]: {
    content: (t) => <Content i18nKey="messages.file-upload-failure" t={t} />,
    type: 'error',
  },
  [POST_SUBMITTED_FOR_MODERATION]: {
    content: (t) => (
      <Content i18nKey="messages.post-submitted-for-moderation" t={t} />
    ),
    type: 'success',
  },
  [POST_UPDATED_IN_MODERATION]: {
    content: (t) => (
      <Content i18nKey="messages.post-updated-in-moderation" t={t} />
    ),
    type: 'success',
  },
};
