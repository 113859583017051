export const POST_RESTORE = 'post-restore';
export const POST_MOVE_TO_TRASH = 'post-move-to-trash';
export const POST_DELETE = 'post-delete';
export const POST_DELETE_ANOTHER_WRITER_EDITING_ERROR =
  'post-delete-another-writer-editing-error';
export const POST_SUBSCRIBED = 'post-subscribe';
export const POST_UNSUBSCRIBED = 'post-unsubscribe';
export const POST_ENABLE_COMMENTING = 'post-enable-commenting';
export const POST_DISABLE_COMMENTING = 'post-disable-commenting';
export const POST_PUBLISH = 'post-publish';
export const POST_UPDATE = 'post-update';
export const POST_REVERTED_TO_DRAFT = 'post-reverted-to-draft';
export const POST_CHANGES_DISCARDED = 'post-changes-discarded';
export const POST_CHANGES_NOT_PUBLISHED = 'post-changes-not-published';
export const NOT_PUBLISHED_BLOG_POST_PUBLISH =
  'not-published-blog-post-publish';
export const POST_MOVE = 'post-move';
export const POST_CHANGE_CATEGORY = 'post-category-change';
export const POST_DUPLICATE = 'post-duplicate';
export const POST_PIN = 'post-pin';
export const POST_UNPIN = 'post-unpin';

export const POST_SUBMITTED_FOR_MODERATION = 'post-submit-for-moderation';
export const POST_UPDATED_IN_MODERATION = 'post-updated-in-moderation';

export const DRAFT_MOVE_TO_TRASH = 'draft-move-to-trash';
export const DRAFT_DELETE = 'draft-delete';
export const DRAFT_DUPLICATE = 'draft-duplicate';

export const COMMENT_UPDATE = 'comment-update';
export const COMMENT_DELETE = 'comment-delete';

export const REQUIRE_POST_FIELDS = 'require-post-fields';

export const REQUIRE_CATEGORY_FIELDS = 'require-category-fields';

export const MEMBER_PROMOTE_WRITER = 'member-promote-moderator';
export const MEMBER_DEMOTE_WRITER = 'member-demote-moderator';

export const MEMBER_BLOCK = 'member-block';
export const MEMBER_UNBLOCK = 'member-unblock';

export const CATEGORY_CREATE = 'category-create';
export const CATEGORY_DELETE = 'category-delete';
export const CATEGORY_UPDATE = 'category-update';
export const CATEGORY_SUBSCRIBED = 'category-subscribed';
export const CATEGORY_UNSUBSCRIBED = 'category-unsubscribed';

export const REQUIRE_PROFILE_FIELDS = 'require-profile-fields';
export const PROFILE_UPDATE = 'profile-update';
export const PROFILE_UPDATE_FAILED = 'profile-update-failed';

export const USER_SUBSCRIBED = 'user-subscribe';
export const USER_UNSUBSCRIBED = 'user-unsubscribe';

export const SETTINGS_UPDATED = 'settings-updated';

export const LOGO_SAVED = 'logo-saved';
